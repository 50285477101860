@import "~@/erp/styles/variables/variables.scss";
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.search-row {
  border-bottom: 1px solid #ededed;
  padding-bottom: 30px;
}

.search_form .el-date-editor--datetimerange.el-input__inner {
  width: 100%;
}

::v-deep {
  .el-date-editor {
    width: 100%;
  }

  .el-divider {
    margin: 30px 0 28px 0 !important;
  }

  .el-drawer__body {
    height: calc(100% - 44px);
  }

  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    border: none;

    .el-upload-dragger {
      width: 100%;
      height: 100%;
    }
  }

  .el-upload-list__item {
    width: 100px;
    height: 100px;
  }

  .el-upload-list__item-thumbnail {
    width: 100px;
    height: 100px;
  }

  .el-upload-list__item-actions {
    width: 100px;
    height: 100px;
  }

  .el-drawer.rtl {
    overflow: scroll;
  }

  .el-form>.el-form-item:nth-child(1),
  .el-form>.el-form-item:nth-child(5) {
    position: relative;

    &:before {
      content: '*';
      color: red;
      position: absolute;
      left: -10px;
      top: 10px;
    }
  }

  .interval-box {
    display: flex;
    align-items: center;
    padding: 0 10px;

    span {
      padding: 0 5px;
    }
  }

  .pay-way {
    position: relative;

    &::before {
      content: '*';
      color: rgb(255, 77, 79);
      position: absolute;
      left: -12px;
      font-size: 14px;
    }
  }
}
