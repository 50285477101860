@import "~@/erp/styles/variables/variables.scss";






















































































.wrapper-status {
  margin-top: 10px;
}
::v-deep {
  .el-icon-plus {
    position: relative;
    top: -20px;
  }
}
