@import "~@/erp/styles/variables/variables.scss";




















































































































































































.bank-name-select {
  /deep/ .el-input__prefix {
    left: 0;
  }
  /deep/ .el-input__inner {
    padding-left: 95px;
  }
  .prefix-select {
    /deep/ .el-input__inner {
      padding: 0 15px;
    }
  }
}
/deep/ .el-input__prefix {
  .prefix-select {
    width: 80px;
  }
}
.el-select-dropdown__item .wrapper {
  display: flex;
  span {
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.tl {
  text-align: left;
  padding-right: 5px;
}
.tr {
  text-align: right;
  padding-left: 5px;
  color: #909399;
}
.flex {
  display: flex;
  align-items: center;
}
.flex-1 {
  flex: 1;
}
